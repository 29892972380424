<template>
  <div class="HSK">
    <div class="banner">
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>HSK</span></h3>
        </div>
        <p class="_jieshao">
          Chinese Proficiency Test (HSK) is an international standardized test, which focuses on the ability of Chinese non first language candidates to communicate in Chinese in their life, study and work.
        </p>
        <p class="_jieshao">
          The examination levels include HSK (Level 1), HSK (Level 2), HSK (Level 3), HSK (Level 4), HSK (Level 5) and HSK (Level 6).
        </p>
        <div class="_detail">
          <el-tabs v-model="activeName">
            <el-tab-pane label="HSK level 1"
                         name="first">
              <div class="text">
                <p class="_jieshao">
                  HSK (Level 1) tests candidates' daily Chinese application ability, which corresponds to level 1 of the international Chinese proficiency standard and level A1 of the European language common reference framework (CEF). Candidates who pass HSK (Level 1) can understand and use some very simple Chinese words and sentences to meet specific communication needs and have the ability to further learn Chinese.
                </p>
                <div class="tit">1、 Candidates for test</div>
                <p class="txt">HSK (Level 1) is mainly for candidates who learn Chinese for one semester (half an academic year) according to the schedule of 2-3 class hours per week and master 150 most commonly used words and related grammar knowledge.</p>
                <div class="tit">2、 Examination content</div>
                <p class="txt">HSK (Level 1) has 40 questions, which are divided into two parts: listening and reading.</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w40">Examination Content</div>
                    <div class="_col w40">Number of question</div>
                    <div class="_col w20">Time</div>
                  </div>
                  <div class="_row">
                    <div class="_col">1. Listening</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                        <div class="_colm">Part 4</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">5</div>
                        <div class="_colm">5</div>
                        <div class="_colm">5</div>
                        <div class="_colm">5</div>
                      </div>
                    </div>
                    <div class="_col">20</div>
                    <div class="_col">About 15</div>
                  </div>
                  <div class="_row">
                    <div class="_col w80">Filling answer sheet (Fill in the answers of the listening part on the answer sheet )</div>
                    <div class="_col w20">3</div>
                  </div>
                  <div class="_row">
                    <div class="_col">1. Reading</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                        <div class="_colm">Part 4</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">5</div>
                        <div class="_colm">5</div>
                        <div class="_colm">5</div>
                        <div class="_colm">5</div>
                      </div>
                    </div>
                    <div class="_col">20</div>
                    <div class="_col">17</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Total</div>
                    <div class="_col">/</div>
                    <div class="_col w40">40</div>
                    <div class="_col">About 35</div>
                  </div>
                </div>
                <p class="txt">All exams take about 40 minutes (including 5 minutes for candidates to fill in their personal information).</p>
                <p class="txt _t">1. Listening</p>
                <p class="txt">The first part consists of 5 questions. Listen to each question twice. Each question is a phrase. A picture is provided on the test paper. The examinee judges right or wrong according to what he hears.</p>
                <p class="txt">The second part consists of 5 questions. Listen to each question twice. Each question is a sentence. Three pictures are provided on the test paper. Candidates choose the corresponding pictures according to what they hear.</p>
                <p class="txt">The third part, 5 questions in total. Listen to each question twice. Each question is a dialogue. Several pictures are provided on the test paper. Candidates choose the corresponding pictures according to what they hear.</p>
                <p class="txt">Part IV, 5 questions in total. Listen to each question twice. Each question is a sentence said by one person. The second person asks a question according to this sentence and says three options. There are three options for each question on the test paper. Candidates choose the answer according to what they hear.</p>
                <p class="txt _t">2. Reading</p>
                <p class="txt">The first part consists of 5 questions. Provide a picture and a word for each question. Candidates should judge whether they are consistent.</p>
                <p class="txt">The second part consists of 5 questions. There are several pictures on the test paper. Each question provides a sentence. Candidates choose the corresponding picture according to the content of the sentence.</p>
                <p class="txt">The third part, 5 questions in total. Provide 5 questions and 5 answers. Candidates should find out the corresponding relationship.</p>
                <p class="txt">Part IV, 5 questions in total. Each question provides a sentence with a space. Candidates should choose words from the options provided to fill in the blanks.</p>
                <p class="txt">All the questions on the test paper are added with Pinyin.</p>
                <div class="tit">3、 Result report</div>
                <p class="txt">The HSK (Level 1) score report provides three scores: listening, reading and total score. A total score of 120 points is qualified.</p>
                <div class="_table"
                     style="width:400px">
                  <div class="_row _head _h-z3">
                    <div class="_col"></div>
                    <div class="_col">Full Score</div>
                    <div class="_col">Your Score</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Listening</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Reading</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Total</div>
                    <div class="_col">200</div>
                    <div class="_col"></div>
                  </div>
                </div>
                <p class="txt">HSK scores are valid for a long time. As a proof of the Chinese language ability of foreign students entering Chinese institutions, the HSK score is valid for two years (from the date of the examination).</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="HSK Level 2"
                         name="second">
              <div class="text">
                <p class="_jieshao">HSK (Level 2) tests candidates' daily Chinese application ability, which corresponds to level 2 of the international Chinese proficiency standard and level A2 of the European language common reference framework (CEF). Candidates who pass HSK (Level 2) can have simple and direct communication on familiar daily topics in Chinese, reaching the excellent level of primary Chinese.</p>
                <div class="tit">1、 Candidates for test</div>
                <p class="txt">HSK (Level 2) is mainly for candidates who learn Chinese for two semesters (one academic year) according to the schedule of 2-3 class hours per week and master 300 most commonly used words and related grammar knowledge.</p>
                <div class="tit">2. Examination content</div>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w40">Examination Content</div>
                    <div class="_col w40">Number of question</div>
                    <div class="_col w20">Time</div>
                  </div>
                  <div class="_row">
                    <div class="_col">1. Listening</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                        <div class="_colm">Part 4</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                      </div>
                    </div>
                    <div class="_col">40</div>
                    <div class="_col">About 35</div>
                  </div>
                  <div class="_row">
                    <div class="_col w80">Filling answer sheet (Fill in the answers of the listening part on the answer sheet )</div>
                    <div class="_col w20">3</div>
                  </div>
                  <div class="_row">
                    <div class="_col">2. Reading</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                        <div class="_colm">Part 4</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">5</div>
                        <div class="_colm">5</div>
                        <div class="_colm">5</div>
                        <div class="_colm">10</div>
                      </div>
                    </div>
                    <div class="_col">25</div>
                    <div class="_col">22</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Total</div>
                    <div class="_col">/</div>
                    <div class="_col w40">60</div>
                    <div class="_col">About 50</div>
                  </div>
                </div>
                <p class="txt">All exams take about 55 minutes (including 5 minutes for candidates to fill in their personal information).</p>
                <p class="txt _t">1. Listening</p>
                <p class="txt">Part one, 10 questions in total. Listen to each question twice. Each question is a sentence. A picture is provided on the test paper. The examinee judges right or wrong according to what he hears.</p>
                <p class="txt">The second part consists of 10 questions. Listen to each question twice. Each question is a dialogue. Several pictures are provided on the test paper. Candidates choose the corresponding pictures according to what they hear.</p>
                <p class="txt">Part III, 10 questions in total. Listen to each question twice. Each question is a two sentence dialogue between two people. The third person asks a question according to the dialogue. Three options are provided on the test paper. Candidates choose the answer according to what they hear.</p>
                <p class="txt">Part IV, 5 questions in total. Listen to each question twice. Each question is a four to five sentence dialogue between two people. The third person asks a question according to the dialogue. There are three options on the test paper. The examinee chooses the answer according to what he hears.</p>
                <p class="txt _t">2. Reading</p>
                <p class="txt">The first part consists of 5 questions. There are several pictures on the test paper. Each question provides a sentence. Candidates choose the corresponding picture according to the content of the sentence.</p>
                <p class="txt">The second part consists of 5 questions. One or two sentences are provided for each question. There is a space in the sentence. Candidates should choose words from the options provided to fill in the blanks.</p>
                <p class="txt">The third part, 5 questions in total. Each question provides two sentences. Candidates should judge whether the content of the second sentence is consistent with the first sentence.</p>
                <p class="txt">Part IV, 10 questions in total. Provide 20 sentences. Candidates should find out the corresponding relationship.</p>
                <p class="txt">All the questions on the test paper are added with Pinyin.</p>
                <div class="tit">3、 Result report</div>
                <p class="txt">The HSK (Level 2) score report provides three scores: listening, reading and total score. A total score of 120 points is qualified.</p>
                <div class="_table"
                     style="width:400px">
                  <div class="_row _head _h-z3">
                    <div class="_col"></div>
                    <div class="_col">Full Score</div>
                    <div class="_col">Your Score</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Listening</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Reading</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Total</div>
                    <div class="_col">200</div>
                    <div class="_col"></div>
                  </div>
                </div>
                <p class="txt">HSK scores are valid for a long time. As a proof of the Chinese language ability of foreign students entering Chinese institutions, the HSK score is valid for two years (from the date of the examination).</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="HSK level 3"
                         name="third">
              <div class="text">
                <p class="_jieshao">HSK (Level 3) tests candidates' Chinese application ability, which corresponds to level 3 of the international Chinese proficiency standard and level B1 of the European language common reference framework (CEF). Candidates who pass HSK (Level 3) can complete basic communication tasks in life, study and work in Chinese, and can deal with most communication tasks when traveling in China.</p>
                <div class="tit">1. Candidates for test</div>
                <p class="txt">HSK (Level 3) is mainly for candidates who master 600 most commonly used words and related grammar knowledge.</p>
                <div class="tit">2. Examination content</div>
                <p class="txt">HSK (Level 3) has a total of 80 questions, which are divided into three parts: listening, reading and writing.</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w40">Examination Content</div>
                    <div class="_col w40">Number of question</div>
                    <div class="_col w20">Time</div>
                  </div>
                  <div class="_row">
                    <div class="_col">1. Listening</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                        <div class="_colm">Part 4</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                      </div>
                    </div>
                    <div class="_col">40</div>
                    <div class="_col">About 35</div>
                  </div>
                  <div class="_row">
                    <div class="_col w80">Filling answer sheet (Fill in the answers of the listening part on the answer sheet )</div>
                    <div class="_col w20">5</div>
                  </div>
                  <div class="_row">
                    <div class="_col">2. Reading</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                      </div>
                    </div>
                    <div class="_col">30</div>
                    <div class="_col">30</div>
                  </div>
                  <div class="_row">
                    <div class="_col">3. Writing</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">5</div>
                        <div class="_colm">5</div>
                      </div>
                    </div>
                    <div class="_col">10</div>
                    <div class="_col">15</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Total</div>
                    <div class="_col">/</div>
                    <div class="_col w40">80</div>
                    <div class="_col">About 85</div>
                  </div>
                </div>
                <p class="txt">All exams take about 90 minutes (including 5 minutes for candidates to fill in their personal information).</p>
                <p class="txt _t">1. Listening</p>
                <p class="txt">Part one, 10 questions in total. Listen to each question twice. Each question is a dialogue. Several pictures are provided on the test paper. Candidates choose the corresponding pictures according to what they hear.</p>
                <p class="txt">The second part consists of 10 questions. Listen to each question twice. Each question is that one person first says a short paragraph, and the other person says a sentence according to this paragraph. This sentence is also provided on the test paper, asking the examinee to judge whether it is right or wrong.</p>
                <p class="txt">Part III, 10 questions in total. Listen to each question twice. Each question is a two sentence dialogue between two people. The third person asks a question according to the dialogue. Three options are provided on the test paper. Candidates choose the answer according to what they hear.</p>
                <p class="txt">Part IV, 10 questions in total. Listen to each question twice. Each question is a four to five sentence dialogue between two people. The third person asks a question according to the dialogue. There are three options on the test paper. The examinee chooses the answer according to what he hears.</p>
                <p class="txt _t">2. Reading</p>
                <p class="txt">Part one, 10 questions in total. Provide 20 sentences. Candidates should find out the corresponding relationship.</p>
                <p class="txt">The second part consists of 10 questions. One or two sentences are provided for each question. There is a space in the sentence. Candidates should choose words from the options provided to fill in the blanks.</p>
                <p class="txt">Part III, 10 questions in total. Provide 10 short paragraphs, each with a question. Candidates should choose the answer from three options.</p>
                <p class="txt _t">3. Writing</p>
                <p class="txt">The first part consists of 5 questions. Provide several words for each question. Ask candidates to write a sentence with these words.</p>
                <p class="txt">The second part consists of 5 questions. Each question provides a sentence with a space. Candidates are required to write correct Chinese characters on the space.</p>
                <div class="tit">3. Result report</div>
                <p class="txt">The HSK (Level 3) score report provides four scores: listening, reading, writing and total score. A total score of 180 points is qualified.</p>
                <div class="_table"
                     style="width:400px">
                  <div class="_row _head _h-z3">
                    <div class="_col"></div>
                    <div class="_col">Full Score</div>
                    <div class="_col">Your Score</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Listening</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Reading</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Writing</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Total</div>
                    <div class="_col">300</div>
                    <div class="_col"></div>
                  </div>
                </div>
                <p class="txt">HSK scores are valid for a long time. As a proof of the Chinese language ability of foreign students entering Chinese institutions, the HSK score is valid for two years (from the date of the examination).</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="HSK level 4"
                         name="fourth">
              <div class="text">
                <p class="_jieshao">HSK (Level 4) tests candidates' Chinese application ability, which corresponds to level 4 of the international Chinese proficiency standard and level B2 of the European language common reference framework (CEF). Candidates who pass HSK (Level 4) can talk about a wide range of topics in Chinese and communicate fluently with native Chinese speakers.</p>
                <div class="tit">1、 Candidates for test</div>
                <p class="txt">HSK (Level 4) is mainly for candidates who learn Chinese for four semesters (two academic years) according to the schedule of 2-4 class hours per week and master 1200 common words.</p>
                <div class="tit">2、 Examination content</div>
                <p class="txt">HSK (Level 4) has 100 questions in total, which is divided into three parts: listening, reading and writing.</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w40">Examination Content</div>
                    <div class="_col w40">Number of question</div>
                    <div class="_col w20">Time</div>
                  </div>
                  <div class="_row">
                    <div class="_col">1. Listening</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">10</div>
                        <div class="_colm">15</div>
                        <div class="_colm">20</div>
                      </div>
                    </div>
                    <div class="_col">45</div>
                    <div class="_col">About 30</div>
                  </div>
                  <div class="_row">
                    <div class="_col w80">Filling answer sheet (Fill in the answers of the listening part on the answer sheet )</div>
                    <div class="_col w20">5</div>
                  </div>
                  <div class="_row">
                    <div class="_col">2. Reading</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                        <div class="_colm">20</div>
                      </div>
                    </div>
                    <div class="_col">40</div>
                    <div class="_col">40</div>
                  </div>
                  <div class="_row">
                    <div class="_col">3. Writing</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">10</div>
                        <div class="_colm">5</div>
                      </div>
                    </div>
                    <div class="_col">15</div>
                    <div class="_col">25</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Total</div>
                    <div class="_col">/</div>
                    <div class="_col w40">100</div>
                    <div class="_col">About 100</div>
                  </div>
                </div>
                <p class="txt">All exams take about 105 minutes (including 5 minutes for candidates to fill in their personal information).</p>
                <p class="txt _t">1. Listening</p>
                <p class="txt">Part one, 10 questions in total. Listen to each question once. Each question is that one person first says a short paragraph, and the other person says a sentence according to this paragraph. This sentence is also provided on the test paper, asking the examinee to judge whether it is right or wrong.</p>
                <p class="txt">The second part consists of 15 questions. Listen to each question once. Each question is a two sentence dialogue between two people. The third person asks a question according to the dialogue. Four options are provided on the test paper. Candidates choose the answer according to what they hear.</p>
                <p class="txt">The third part, a total of 20 questions. Listen to each question once. This part of the test questions are 4 to 5 sentences of dialogue or a short paragraph. Ask one or two questions according to the dialogue or paragraph. Each question on the test paper provides four options. Candidates choose the answers according to what they hear.</p>
                <p class="txt _t">2. Reading</p>
                <p class="txt">2. Reading</p>
                <p class="txt">The second part consists of 10 questions. Three sentences are provided for each question. Candidates should arrange the three sentences in order.</p>
                <p class="txt">The third part, a total of 20 questions. This part of the test questions are a short paragraph of text, each paragraph of text with one or two questions. Candidates should choose the answers from four options.</p>
                <p class="txt _t">3. Writing</p>
                <p class="txt">Part one, 10 questions in total. Provide several words for each question. Ask candidates to write a sentence with these words.</p>
                <p class="txt">Part one, 10 questions in total. Provide several words for each question. Ask candidates to write a sentence with these words.</p>
                <div class="tit">3、 Result report</div>
                <p class="txt">The HSK (Level 4) score report provides four scores for listening, reading, writing and total score. A total score of 180 points is qualified.</p>
                <div class="_table"
                     style="width:400px">
                  <div class="_row _head _h-z3">
                    <div class="_col"></div>
                    <div class="_col">Full Score</div>
                    <div class="_col">Your Score</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Listening</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Reading</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Writing</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Total</div>
                    <div class="_col">300</div>
                    <div class="_col"></div>
                  </div>
                </div>
                <p class="txt">HSK scores are valid for a long time. As a proof of the Chinese language ability of foreign students entering Chinese institutions, the HSK score is valid for two years (from the date of the examination).</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="HSK level 5"
                         name="fifth">
              <div class="text">
                <p class="_jieshao">HSK (Level 5) tests candidates' Chinese application ability, which corresponds to level 5 of the international Chinese proficiency standard and level C1 of the European language common reference framework (CEF). Candidates who pass HSK (Level 5) can read Chinese newspapers and magazines, enjoy Chinese film and television programs, and give a more complete speech in Chinese.</p>
                <div class="tit">1、 Candidates for test</div>
                <p class="txt">HSK (Level 5) is mainly for candidates who have studied Chinese for more than two years according to the schedule of 2-4 class hours per week and master 2500 common words.</p>
                <div class="tit">2、 Examination content</div>
                <p class="txt">HSK (Level 5) has 100 questions in total, which is divided into three parts: listening, reading and writing.</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w40">Examination Content</div>
                    <div class="_col w40">Number of question</div>
                    <div class="_col w20">Time</div>
                  </div>
                  <div class="_row">
                    <div class="_col">1. Listening</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">20</div>
                        <div class="_colm">25</div>
                      </div>
                    </div>
                    <div class="_col">45</div>
                    <div class="_col">About 30</div>
                  </div>
                  <div class="_row">
                    <div class="_col w80">Filling answer sheet (Fill in the answers of the listening part on the answer sheet )</div>
                    <div class="_col w20">5</div>
                  </div>
                  <div class="_row">
                    <div class="_col">2. Reading</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">15</div>
                        <div class="_colm">10</div>
                        <div class="_colm">20</div>
                      </div>
                    </div>
                    <div class="_col">45</div>
                    <div class="_col">45</div>
                  </div>
                  <div class="_row">
                    <div class="_col">3. Writing</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">8</div>
                        <div class="_colm">2</div>
                      </div>
                    </div>
                    <div class="_col">10</div>
                    <div class="_col">40</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Total</div>
                    <div class="_col">/</div>
                    <div class="_col w40">100</div>
                    <div class="_col">About 120</div>
                  </div>
                </div>
                <p class="txt">All exams take about 125 minutes (including 5 minutes for candidates to fill in their personal information).</p>
                <p class="txt _t">1. Listening</p>
                <p class="txt">Part one, 20 questions in total. Listen to each question once. Each question is a two sentence dialogue between two people. The third person asks a question according to the dialogue. Four options are provided on the test paper. Candidates choose the answer according to what they hear.</p>
                <p class="txt">The second part, a total of 25 questions. Listen to each question once. This part of the test questions are 4 to 5 sentences of dialogue or a paragraph. Ask one or more questions according to the dialogue or paragraph. Each question on the test paper provides 4 options. Candidates choose the answers according to what they hear.</p>
                <p class="txt _t">2. Reading</p>
                <p class="txt">The first part consists of 15 questions. Provide several texts. There are several spaces in each text. Fill in a word or a sentence in the space. Each space has four options. Candidates should choose the answer from them.</p>
                <p class="txt">The second part consists of 10 questions. Each question provides a paragraph and four options. Candidates should choose one that is consistent with the content of this paragraph.</p>
                <p class="txt">The third part, a total of 20 questions. Provide several texts, each with several questions. Candidates should choose the answers from four options.</p>
                <p class="txt _t">3. Writing</p>
                <p class="txt">The first part consists of 8 questions. Provide several words for each question. Ask candidates to write a sentence with these words.</p>
                <p class="txt">Part II, 2 questions in total. The first question provides several words and asks the examinee to write a short passage of about 80 words with these words; The second question provides a picture and asks the examinee to write an essay of about 80 words.</p>
                <div class="tit">3、 Result report</div>
                <p class="txt">The HSK (Level 5) score report provides four scores: listening, reading, writing and total score.</p>
                <div class="_table"
                     style="width:400px">
                  <div class="_row _head _h-z3">
                    <div class="_col"></div>
                    <div class="_col">Full Score</div>
                    <div class="_col">Your Score</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Listening</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Reading</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Writing</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Total</div>
                    <div class="_col">300</div>
                    <div class="_col"></div>
                  </div>
                </div>
                <p class="txt">HSK scores are valid for a long time. As a proof of the Chinese language ability of foreign students entering Chinese institutions, the HSK score is valid for two years (from the date of the examination).</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="HSK level 6"
                         name="sixth">
              <div class="text">
                <p class="_jieshao">HSK (Level 6) tests candidates' Chinese application ability, which corresponds to level 5 of the international Chinese proficiency standard and level C2 of the European language common reference framework (CEF). Candidates who pass HSK (Level 6) can easily understand the Chinese information they hear or read, and express their opinions fluently in Chinese in oral or written form.</p>
                <div class="tit">1、 Candidates for test</div>
                <p class="txt">HSK (Level 6) is mainly for candidates who master common words of 5000 and above.</p>
                <div class="tit">2、 Examination content</div>
                <p class="txt">HSK (Level 6) has 101 questions, which are divided into three parts: listening, reading and writing.</p>
                <div class="_table">
                  <div class="_row _head">
                    <div class="_col w40">Examination Content</div>
                    <div class="_col w40">Number of question</div>
                    <div class="_col w20">Time</div>
                  </div>
                  <div class="_row">
                    <div class="_col">1. Listening</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">15</div>
                        <div class="_colm">15</div>
                        <div class="_colm">20</div>
                      </div>
                    </div>
                    <div class="_col">50</div>
                    <div class="_col">About 35</div>
                  </div>
                  <div class="_row">
                    <div class="_col w80">Filling answer sheet (Fill in the answers of the listening part on the answer sheet )</div>
                    <div class="_col w20">5</div>
                  </div>
                  <div class="_row">
                    <div class="_col">2. Reading</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Part 1</div>
                        <div class="_colm">Part 2</div>
                        <div class="_colm">Part 3</div>
                        <div class="_colm">Part 4</div>
                      </div>
                    </div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                        <div class="_colm">10</div>
                        <div class="_colm">20</div>
                      </div>
                    </div>
                    <div class="_col">50</div>
                    <div class="_col">50</div>
                  </div>
                  <div class="_row">
                    <div class="_col">3. Writing</div>
                    <div class="_col">
                      <div class="nei">
                        <div class="_colm">Essay</div>
                      </div>
                    </div>
                    <div class="_col w40">
                      1
                    </div>
                    <div class="_col">45</div>
                  </div>
                  <div class="_row">
                    <div class="_col">Total</div>
                    <div class="_col">/</div>
                    <div class="_col w40">101</div>
                    <div class="_col">About 135</div>
                  </div>
                </div>
                <p class="txt">All exams take about 140 minutes (including 5 minutes for candidates to fill in their personal information).</p>
                <p class="txt _t">1. Listening</p>
                <p class="txt">The first part consists of 15 questions. Listen to each question once. Play a short paragraph for each question. There are four options on the test paper. Candidates choose one that is consistent with what they hear.</p>
                <p class="txt">The second part consists of 15 questions. Listen to each question once. Play three interviews, with 5 questions after each interview. Each question on the test paper provides 4 options. Candidates choose the answers according to what they hear.</p>
                <p class="txt">The third part, a total of 20 questions. Listen to each question once. Play several paragraphs with several questions after each paragraph. Each question on the test paper provides four options. Candidates choose the answers according to what they hear.</p>
                <p class="txt _t">2. Reading</p>
                <p class="txt">Part one, 10 questions in total. Four sentences are provided for each question. Candidates are required to choose the sentence with language defects.</p>
                <p class="txt">The second part consists of 10 questions. Each question provides a short paragraph of text with 3 to 5 spaces. Candidates should choose the most appropriate answer from the four options in combination with the context.</p>
                <p class="txt">Part III, 10 questions in total. Provide two texts, each text has five spaces. Candidates should choose the answer from the five sentence options provided in combination with the context.</p>
                <p class="txt">Part IV, 20 questions in total. Provide several texts, each with several questions. Candidates should choose the answers from four options.</p>
                <p class="txt _t">3. Writing</p>
                <p class="txt">Candidates should first read a narrative article of about 1000 words for 10 minutes. They can't copy and record when reading; After the invigilator takes back the reading materials, please abbreviate this article into a short passage of about 400 words for 35 minutes. The title is self-made. Just repeat the content of the article without adding your own views.</p>
                <div class="tit">3、 Result report</div>
                <p class="txt">The HSK (Level 6) score report provides four scores: listening, reading, writing and total score.</p>
                <div class="_table"
                     style="width:400px">
                  <div class="_row _head _h-z3">
                    <div class="_col"></div>
                    <div class="_col">Full Score</div>
                    <div class="_col">Your Score</div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Listening</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Reading</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Writing</div>
                    <div class="_col">100</div>
                    <div class="_col"></div>
                  </div>
                  <div class="_row _h-z3">
                    <div class="_col">Total</div>
                    <div class="_col">300</div>
                    <div class="_col"></div>
                  </div>
                </div>
                <p class="txt">HSK scores are valid for a long time. As a proof of the Chinese language ability of foreign students entering Chinese institutions, the HSK score is valid for two years (from the date of the examination).</p>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
    }
  },
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
._jieshao {
  font-size: 14px;
  line-height: 1.8;
  text-indent: 2em;
  margin-bottom: 10px;
}
._detail {
  margin-top: 40px;
  .el-tabs ::v-deep {
    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }
    .el-tabs__item {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .text {
    padding: 20px 0;
    .txt {
      text-indent: 2em;
      font-size: 14px;
      margin-top: 10px;
      color: #363636;
      &._t {
        font-weight: bold;
        text-indent: 0;
      }
    }
  }
}
._table {
  margin: 0 auto;
  margin-top: 20px;
  ._row {
    border: 1px solid #dedede;
    margin-bottom: -1px;
    overflow: hidden;
    display: flex;
    &._head {
      background-color: #f1f1f1;
    }
    ._col {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 40px;
      border-right: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      text-align: center;
      margin-bottom: -1px;
      &:last-child {
        border-right: none;
      }
      &.w80 {
        width: 80%;
      }

      &.w75 {
        width: 75%;
      }
      &.w50 {
        width: 50%;
      }
      &.w25 {
        width: 25%;
      }
      &.w40 {
        width: 40%;
      }
      &.w20 {
        width: 20%;
      }
    }
    &._h-z3 {
      ._col {
        width: 33.333%;
      }
    }
  }
  .nei {
    width: 100%;
    ._colm {
      width: 100%;
      border-bottom: 1px solid #dedede;
      line-height: 40px;
      text-align: center;
      margin-bottom: -1px;
    }
  }
}
</style>